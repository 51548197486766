import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@logic-suite/shared/auth';
import { CustomerGuard } from './shared/access';
import { HasPrivilegesGuard } from '@suite/acl-data-access';
import { PrivilegeName } from '../../../../libs/suite/user-management/privileges/models/src/lib/privilege-name.enum';
import { PrivilegeAccessStrategy } from '@suite/privileges-models';

const routes: Routes = [
  // Default redirect
  {
    path: '',
    redirectTo: 'energy',
    pathMatch: 'full',
  },

  // Primary navigation routes (in rail order)
  {
    path: 'energy',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.energy, PrivilegeName.environment, PrivilegeName.financial],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      }),
    ],
    loadChildren: () => import('./views/energy/dashboard.module').then((m) => m.DashboardModule),
    title: 'Energy | Noova Energy',
  },
  {
    path: 'advise',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.advise],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      }),
    ],
    loadChildren: () => import('./views/advise/dashboard.module').then((m) => m.DashboardModule),
    title: 'Notifications | Noova Energy',
  },
  {
    path: 'sense',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.sense],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      }),
    ],
    loadChildren: () => import('./views/sense/dashboard.module').then((m) => m.DashboardModule),
    title: 'Sensors | Noova Energy',
  },
  {
    path: 'facility',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.facility],
        accessStrategy: 'anyNode',
      }),
    ],
    loadChildren: () => import('./views/facility/dashboard.module').then((m) => m.DashboardModule),
    title: 'Facility | Noova Energy',
  },
  {
    path: 'financial', // Note: This path is named 'invoice' in the routes.
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.financial],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      }),
    ],
    loadChildren: () => import('./views/invoice/invoice.module').then((m) => m.InvoiceModule),
    title: 'Invoice | Noova Energy',
  },
  {
    path: 'asset-log',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.energy],
        accessStrategy: PrivilegeAccessStrategy.anyNodeOfTypes,
        resources: [
          {
            type: 'customer',
            id: '',
          },
          {
            type: 'assetGroup',
            id: '',
          },
          {
            type: 'asset',
            id: '',
          },
        ],
      }),
    ],
    loadChildren: async () => (await import('@suite/asset-log-shell')).AssetLogShellModule,
    title: 'Asset Log | Noova Energy',
  },

  // Secondary navigation routes (in rail order)
  {
    path: 'config',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.assetConfig],
        accessStrategy: PrivilegeAccessStrategy.anyNode,
      }),
    ],
    loadChildren: () => import('./views/configuration/configuration.module').then((m) => m.ConfigurationModule),
    title: 'Configuration | Noova Energy',
  },
  {
    path: 'acl',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.aclConfig, PrivilegeName.aclAssignment],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      }),
    ],
    loadChildren: async () => (await import('@suite/acl-shell')).AclShellModule,
    title: 'User Access | Noova Energy',
  },
  {
    path: 'users',
    canActivate: [
      AuthGuard,
      CustomerGuard,
      HasPrivilegesGuard({
        privilegeNames: [PrivilegeName.subscriptions],
        accessStrategy: PrivilegeAccessStrategy.rootOnly,
      }),
    ],
    loadChildren: () => import('./views/user-management/user-management.module').then((m) => m.UserManagementModule),
    title: 'User Management | Noova Energy',
  },

  // Profile (appears at bottom of rail)
  {
    path: 'profile',
    canActivate: [AuthGuard, CustomerGuard],
    loadChildren: async () => (await import('@suite/profile')).ProfileModule,
    title: 'Profile | Noova Energy',
  },

  // Utility routes (not in rail directly)
  {
    path: 'select-customer',
    loadChildren: () => import('./views/select-customer/select-customer.module').then((m) => m.CustomerModule),
    canActivate: [AuthGuard],
    data: { animations: 'backslide' },
    title: 'Select Customer | Noova Energy',
  },

  // Special routes
  {
    path: 'access-denied',
    loadComponent: () => import('./views/access-denied/access-denied.component').then((m) => m.AccessDeniedComponent),
    title: 'Access Denied | Noova Energy',
  },

  {
    path: '404',
    loadComponent: () => import('./views/not-found/not-found.component').then((m) => m.NotFoundComponent),
    title: '404 - Page Not Found | Noova Energy',
  },

  // Fallback route - redirects to 404 page
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

export const AppRoutes = RouterModule.forRoot(routes, {
  enableViewTransitions: true,
});
